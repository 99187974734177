import { useEffect } from "react";
import { navigate } from "gatsby";
import { getParameterByName } from "../common/utils";
import { REFERRAL_ID_STORAGE_KEY } from "../constants";

const Referral = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const id = getParameterByName("id");
      if (id) {
        localStorage.setItem(REFERRAL_ID_STORAGE_KEY, id);
        navigate("/");
      }
    }
  }, []);

  return null;
};

export default Referral;
